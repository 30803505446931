import React, { useState } from 'react'
import { syncBalance } from './balanceUtils'

const ProfilePic = ({ profilePic, username, ordoscopeVersion, balance, afterSyncHandler }) => {
    const [syncing, setSyncing] = useState(false)

    return(
        <div className='ProfilePicWrapper' onClick={async () => {
            // if (ordoscopeVersion && ordoscopeVersion !== '' && ordoscopeVersion !== null && ordoscopeVersion !== undefined) {
            //     window.location.href = `/?scope=${ordoscopeVersion}`;
            // } else {
            //     window.location.href = '/';
            // }
            setSyncing(true)
            await syncBalance()
            setSyncing(false)
            afterSyncHandler && afterSyncHandler()
        }}>
            <div style={{width: 32, height: 32}}>
                <img src={profilePic} alt="Profile Picture" style={{width: 32, height: 32, borderRadius: 16}} />
            </div>
            <div className='username'>
                {syncing && <span style={{color: 'red'}}>Syncing...</span>}
                {!syncing && balance}
            </div>
        </div>
    )
}

export default React.memo(ProfilePic)