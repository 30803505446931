import { useEffect, useState } from "react";
import db from "./Firebase";
import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import { getDocsx, getDocx, docx, setDocx, collectionx, queryx } from "./SubtaskNewServer"

import payrate from "./payrate";
import "./Edit.css";
import Button from "react-bootstrap/Button";
import moment from "moment";
import _ from "lodash";

import { IoAdd } from "react-icons/io5";
import { AiFillDelete, AiFillCheckCircle, AiFillSave, AiFillStar } from "react-icons/ai"
import { BiReset } from "react-icons/bi"
import { FaArrowDown, FaArrowUp, FaPlus, FaSync } from "react-icons/fa";
import useWindowDimensions from "./useWindowDimensions";

import mqtt from "precompiled-mqtt"

import Form from "react-bootstrap/Form";
import { MdDelete, MdSave } from "react-icons/md";
import { FaAngleRight, FaChevronDown } from "react-icons/fa";

import mqttUsername from "./MqttUsername"
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { syncBalance } from "./balanceUtils";

function Edit() {

  const mqttUpdate = () => {
    const client = mqtt.connect("wss://paymemobile.fr:9001", {
      clientId: "web_" + Math.random().toString(16).substr(2, 8),
      username: "root",
      password: "tQ@*9XXiJZ^G$v"
    });
    // MQTT client setup
    client.on("connect", () => {
      console.log("MQTT connected");
      client.subscribe("control_" + mqttUsername, (err) => {
        if (err) {
          console.log("MQTT subscribe to control channel error: ", err);
        } else {
          client.publish("control_" + mqttUsername, "tasks");
          client.end()
        }
      });
    });

    client.on("error", (err) => {
      console.log("MQTT error: ", err);
    });

    client.on("close", () => {
      console.log("MQTT connection closed");
    });
  }

  const [stamp, setStamp] = useState([]);
  const [dailySn, setDailySn] = useState("");
  const [data, setData] = useState({});
  const [totalTime, setTotalTime] = useState(0);
  const [totalTimeReward, setTotalTimeReward] = useState(0);
  const [optUpdateFlag, setOptUpdateFlag] = useState(0);
  const [doneList, setDoneList] = useState([]);
  const [subsubHistoryToSync, setSubsubHistoryToSync] = useState([]); // to help sync the subsubHistory, we need another list to store only the newly added subsubHistory
  const [doneListForSubs, setDoneListForSubs] = useState([]);
  const [enableDelete, setEnableDelete] = useState(true);
  const { height, width } = useWindowDimensions();
  // const [dailyTemplates, setDailyTemplates] = useState([
  //   {
  //     name: 'Test1',
  //     subs: [
  //       { sname: 'Reinf Lrn', time: 1, finish: 0.68 },
  //       {
  //         sname: 'mcts',
  //         time: 1,
  //         finish: 0.68,
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Test2',
  //     subs: [],
  //   },
  // ])
  const [dailyTemplates, setDailyTemplates] = useState([])
  const [selectedDailyTemplate, setSelectedDailyTemplate] = useState('')
  const [nameDailyTemplate, setNameDailyTemplate] = useState('')
  const [templateExpanded, setTemplateExpanded] = useState(false)
  // const payrate = 0.5

  const [viewOption, setViewOption] = useState("notpostponed")

  const [savingStamp, setSavingStamp] = useState(false)
  const [stampId, setStampId] = useState("")

  const loadStamp = async () => {
    setSavingStamp(true)
    let subs = [];
    // Load the tasks
    const querySnapshot = await getDocsx(queryx(collectionx(db, "subtasks")));
    console.log("querySnapshot", querySnapshot)
    if (querySnapshot.size == 0) {
      return;
    }
    querySnapshot.forEach((doc) => {
      if (doc.id.indexOf("DAILY") > -1) {
        let data = doc.data();
        setData(Object.assign({}, data));
        setDailySn(doc.id);
        setStampId(data.id)

        console.log("data", data)
        console.log("data.id", data.id)
        console.log("doc.id", doc.id)

        // Change the format of the validFromDate and expiryDate
        data.subs.forEach((sub) => {
          if (sub.hasOwnProperty("validFromDate")) {
            if (sub.validFromDate != null && sub.validFromDate != undefined && sub.validFromDate != "") {
              sub.validFromDate = moment(sub.validFromDate.toDate()).format(
                "DD/MM/YYYY HH:mm:ss"
              );
            }
          }
          if (sub.hasOwnProperty("expiryDate")) {
            if (sub.expiryDate != null && sub.expiryDate != undefined && sub.expiryDate != "") {
              sub.expiryDate = moment(sub.expiryDate.toDate()).format(
                "DD/MM/YYYY HH:mm:ss"
              );
            }
          }
          if (sub.hasOwnProperty('allowFeedback')) {
            sub.allowFeedback = String(sub.allowFeedback)
          }

          if (sub.hasOwnProperty('subsubs'))
          {
            sub.subsubs.forEach((subsub) => {
              if (subsub.hasOwnProperty('validFrom')) {
                subsub.validFrom = moment(subsub.validFrom).format(
                  "DD/MM/YYYY HH:mm:ss"
                );
              }
            })
          }
        });

        subs = data.subs;
        setStamp(subs);
      }

      if (doc.id.indexOf("DAILY") > -1) {
        // This is a daily task, we extract its serial number!

        // subs.forEach((sub) => {
        //     if (sub.hasOwnProperty("finish"))
        //     {
        //         sub.finish = sub.finish / payrate * 0.5
        //         // finish should be in "standard time unit, which is 25 minutes"
        //         // the pay is given in this standard time unit. The timer we choose (e.g., 30 minutes)
        //         // should be independent
        //     }
        // })
      }

    });
    // subs.forEach((sub) => {
    //     sub.initialFinish = sub.finish
    // })





    const reportSnapshot = await getDocsx(queryx(collectionx(db, "reports")));
    if (reportSnapshot.size > 0) {
      setEnableDelete(false);
      console.log("report is not empty, disable deleting task")
    }

    // Load doneList collection 
    const doneListQuerySnapshot = await getDocsx(queryx(collectionx(db, "doneList")));
    if (doneListQuerySnapshot.size > 0) {
      doneListQuerySnapshot.forEach((doc) => {
        if (doc.id == "default") {
          let data = doc.data();
          // console.log(data)
          if (data.hasOwnProperty("doneList")) {
            // doneList is not empty set doneList (so that they can be appended, not overwritten)
            setDoneList(data.doneList);
            console.log("doneList set");
          }
        }
      });
    }

    // Load doneListForSubs collection
    const doneListForSubsQuerySnapshot = await getDocsx(queryx(collectionx(db, "doneListForSubs")));
    if (doneListForSubsQuerySnapshot.size > 0) {
      doneListForSubsQuerySnapshot.forEach((doc) => {
        if (doc.id == "default") {
          let data = doc.data();
          // console.log(data)
          if (data.hasOwnProperty("doneListForSubs")) {
            // doneList is not empty set doneList (so that they can be appended, not overwritten)
            setDoneListForSubs(data.doneListForSubs);
            console.log("doneListForSubs set");
          }
        }
      });
    }

    // console.log(subs);
    setSavingStamp(false)
  };

  useEffect(() => {

    // Set document title
    document.title = "Edit Tasks"

    // If locked redirect to home
    if (localStorage.getItem('locked') == 'true') {
      window.location.href = '/'
    }
    
    loadStamp();
    loadTemplates();
  }, []);

  const isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const make_serial = (length, terms) => {
    var result = [];
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (let term = 0; term < terms; term++) {
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      if (term != terms - 1) result.push("-");
    }
    return result.join("");
  };

  const handleTextChange = (e, fieldname, sn) => {
    if (fieldname == "sname") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["sname"] = e.target.value;
      setStamp(newStamp);
    }
    if (fieldname == "pay") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["finish"] = e.target.value;
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["finish"] = Number(e.target.value);
        newStamp[indexOfSub]["initialFinish"] = Number(e.target.value);
      }
      setStamp(newStamp);
    }
    if (fieldname == "time") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["time"] = e.target.value;
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["time"] = Number(e.target.value);
        newStamp[indexOfSub]["finish"] = roundToSecondDecimal(Number(e.target.value) * payrate);
        newStamp[indexOfSub]["initialFinish"] =
          Number(e.target.value) * payrate;
      }
      setStamp(newStamp);
    }
    if (fieldname == "bonusCoeff") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["bonusCoeff"] = e.target.value;
      /* if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]["bonusCoeff"] = Number(e.target.value);
      } else {
        newStamp[indexOfSub]["bonusCoeff"] = e.target.value;
      } */
      setStamp(newStamp);
    }
    if (fieldname == "originalTime") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["originalTime"] = e.target.value;
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["originalTime"] = Number(e.target.value);
        newStamp[indexOfSub]["originalFinish"] =
          Number(e.target.value) * payrate;
        newStamp[indexOfSub]["time"] = Number(e.target.value);
        newStamp[indexOfSub]["finish"] = roundToSecondDecimal(Number(e.target.value) * payrate);
        newStamp[indexOfSub]["initialFinish"] =
          Number(e.target.value) * payrate;
      }
      setStamp(newStamp);
    }
    if (fieldname == "originalFinish") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["originalFinish"] = e.target.value;
      if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]["finish"] = Number(e.target.value);
        newStamp[indexOfSub]["initialFinish"] = Number(e.target.value);
      }
      setStamp(newStamp);
    }
    if (fieldname == "tags") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["tags"] = e.target.value;
      setStamp(newStamp);
    }
    if (fieldname == "allowFeedback") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["allowFeedback"] = e.target.value;
      setStamp(newStamp);
    }
    // update optUpdateFlag
    setOptUpdateFlag(optUpdateFlag + 1);
  };

  const setTimeByButton = (sn, value) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn);
    let newStamp = Object.assign([], stamp);
    newStamp[indexOfSub]["time"] = value;
    newStamp[indexOfSub]["finish"] = roundToSecondDecimal(value * payrate);
    newStamp[indexOfSub]["initialFinish"] = value * payrate;
    setStamp(newStamp);
  };

  const deleteSub = (sn) => {
    let newStamp = Object.assign([], stamp);
    newStamp = newStamp.filter((x) => x.sn != sn);
    setStamp(newStamp);
  };

  const addSubSub = (sn) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn);
    let newStamp = Object.assign([], stamp);
    newStamp[indexOfSub]["subsubs"].push({
      sn: make_serial(6, 6),
      name: "Subtask Name",
      finish: 1,
    });
    setStamp(newStamp);
  };

  const deleteSubSub = (sn1, sn2) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let newStamp = Object.assign([], stamp);
    newStamp[indexOfSub]["subsubs"] = newStamp[indexOfSub]["subsubs"].filter(
      (x) => x.sn != sn2
    );
    setStamp(newStamp);
  };

  const addToDoneList = (sn1, sn2, ssname, sspay) => {
    // sn1: sn of the subtask
    // sn2: sn of the subsubtask
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let indexOfSubSub = stamp[indexOfSub]["subsubs"].findIndex(
      (x) => x.sn == sn2
    );
    let sname = stamp[indexOfSub]["sname"];
    let doneListPrime = _.cloneDeep(doneList);
    // if sn1 is not in doneList, add it
    if (!doneListPrime.some((x) => x.sn == sn1)) {
      doneListPrime.push({
        sn: sn1,
        sname: sname,
        stampId: stampId,
        ss: []
      });
    }
    // Append sn2 to ss of the corresponding element whose sn=sn1
    doneListPrime.forEach((x) => {
      if (x.sn == sn1) {
        x.ss.push({
          sn: sn2,
          name: ssname,
          finish: Number(sspay)
        });
      }
    }
    );
    setDoneList(doneListPrime);

    // Add to subsubHistoryToSync
    let mSubsubHistoryToSync = _.cloneDeep(subsubHistoryToSync);
    mSubsubHistoryToSync.push({
      "sname": sname + "/" + ssname,
      "time": new Date() 
    });
    setSubsubHistoryToSync(mSubsubHistoryToSync);

    // Remove sn2 from subsubs of the corresponding element whose sn=sn1
    let newStamp = Object.assign([], stamp);
    newStamp[indexOfSub]["subsubs"] = newStamp[indexOfSub]["subsubs"].filter(
      (x) => x.sn != sn2
    );
    setStamp(newStamp);
  };

  const addToDoneListWithRecall = (sn1, sn2, ssname, sspay, daysFromNow) => {
    // Convert daysFromNow to Date
    let today = moment();
    let validFromDate = today.add(daysFromNow, 'days').set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0).format("DD/MM/YYYY HH:mm:ss");
    // Add to doneList
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let doneListPrime = _.cloneDeep(doneList);
    let sname = stamp[indexOfSub]["sname"];
    // if sn1 is not in doneList, add it
    if (!doneListPrime.some((x) => x.sn == sn1)) {
      doneListPrime.push({
        sn: sn1,
        sname: sname,
        stampId: stampId,
        ss: []
      });
    }
    // Append sn2 to ss of the corresponding element whose sn=sn1
    doneListPrime.forEach((x) => {
      if (x.sn == sn1) {
        x.ss.push({
          sn: sn2,
          name: ssname,
          finish: Number(sspay)
        });
      }
    }
    );
    setDoneList(doneListPrime);

    // Add to subsubHistoryToSync
    let mSubsubHistoryToSync = _.cloneDeep(subsubHistoryToSync);
    mSubsubHistoryToSync.push({
      "sname": sname + "/" + ssname,
      "time": new Date() 
    });
    setSubsubHistoryToSync(mSubsubHistoryToSync);
    
    let newStamp = Object.assign([], stamp);
    // Remove sn2 from subsubs of the corresponding element whose sn=sn1
    newStamp[indexOfSub]["subsubs"] = newStamp[indexOfSub]["subsubs"].filter(
      (x) => x.sn != sn2
    );
    // Add the ssname back to the subsubs
    newStamp[indexOfSub]["subsubs"].push({
      sn: make_serial(6, 6),
      name: '[R] ' + ssname,
      finish: Number(sspay),
      validFrom: validFromDate
    });
    setStamp(newStamp);
  }

  const addToDoneListForSubs = (sn1) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let doneListForSubsPrime = _.cloneDeep(doneListForSubs);
    let sname = stamp[indexOfSub]["sname"];
    // if sn1 is not in doneListForSubs, add it
    if (!doneListForSubsPrime.some((x) => x.sn == sn1)) {
      let finish = Number(stamp[indexOfSub]["finish"]);
      doneListForSubsPrime.push({
        sn: sn1,
        sname: sname,
        stampId: stampId,
        finish: finish
      });
    }
    setDoneListForSubs(doneListForSubsPrime);
    console.log(doneListForSubsPrime);
    // Add to subsubHistoryToSync
    let mSubsubHistoryToSync = _.cloneDeep(subsubHistoryToSync);
    mSubsubHistoryToSync.push({
      "sname": sname,
      "time": new Date() 
    });
    setSubsubHistoryToSync(mSubsubHistoryToSync);
    // Remove sn1 from stamp
    let newStamp = Object.assign([], stamp);
    newStamp = newStamp.filter((x) => x.sn != sn1);
    setStamp(newStamp);
  }

  const handleSubTextChange = (e, sn1, sn2, fieldname) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let indexOfSubSub = stamp[indexOfSub]["subsubs"].findIndex(
      (x) => x.sn == sn2
    );
    let newStamp = Object.assign([], stamp);
    if (fieldname == "name") {
      newStamp[indexOfSub]["subsubs"][indexOfSubSub]["name"] = e.target.value;
    }
    if (fieldname == "finish") {
      newStamp[indexOfSub]["subsubs"][indexOfSubSub]["finish"] =
        e.target.value;
      /* if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["finish"] = Number(
          e.target.value
        );
      } else {
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["finish"] =
          e.target.value;
      } */
    }
    if (fieldname == "validFrom") {
      // if e.target.value contains the string 'd', it means that the user has entered the number of days
      let xdString = e.target.value
      if (xdString.indexOf('d') > -1) {
        try {
        // remove the 'd' character
        xdString = xdString.replace('d', '')
        // convert to number
        let xd = Number(xdString)
        // convert to Date
        let validFromDate = moment().add(xd, 'days').set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0).format("DD/MM/YYYY HH:mm:ss");
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["validFrom"] = validFromDate
        } catch (error) {
          newStamp[indexOfSub]["subsubs"][indexOfSubSub]["validFrom"] = xdString
        }
      } else {
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["validFrom"] = xdString
      }
    } 
    setStamp(newStamp);
  };

  const addSub = () => {
    let newStamp = Object.assign([], stamp);
    newStamp.push({
      bonusCoeff: 1,
      finish: payrate,
      originalFinish: payrate,
      originalTime: 1,
      initialFinish: payrate,
      time: 1,
      sname: "New task",
      sn: make_serial(6, 6),
      subsubs: [],
      type: "subtask",
    });
    setStamp(newStamp);
  };

  const castData = (mData) => {
    let newData = _.cloneDeep(mData)
    console.log("newData", newData.subs[0]);
    newData.subs.forEach(x => {
      // Convert finish, time, originalFinish, originalTime, initialFinish, bonusCoeff to number
      x.finish = Number(x.finish);
      x.time = Number(x.time);
      x.originalFinish = Number(x.originalFinish);
      x.originalTime = Number(x.originalTime);
      x.initialFinish = Number(x.initialFinish);
      x.bonusCoeff = Number(x.bonusCoeff);
      // Convert allowFeedback which is a string to number
      if (x.hasOwnProperty("allowFeedback")) {
        x.allowFeedback = Number(x.allowFeedback)
      }

      // Convert validFromDate and expiryDate to Date
      if (x.hasOwnProperty("validFromDate")) {
        // if validFromDate is not null or empty, convert to Date
        if (x.validFromDate != null && x.validFromDate != "") {
          // parse to date from string

          x.validFromDate = moment(x.validFromDate, "DD/MM/YYYY HH:mm:ss").toDate();
        } else {
          delete x.validFromDate;
        }
      }

      if (x.hasOwnProperty("expiryDate")) {
        if (x.expiryDate != null && x.expiryDate != "") {
          x.expiryDate = moment(x.expiryDate, "DD/MM/YYYY HH:mm:ss").toDate();
        } else {
          delete x.expiryDate;
        }
      }

      // if has subsubs, convert finish to number
      if (x.hasOwnProperty("subsubs")) {
        x.subsubs.forEach(y => {
          y.finish = Number(y.finish);
          if (y.hasOwnProperty("validFrom")) {
            if (y.validFrom != null && y.validFrom != "") {
              y.validFrom = moment(y.validFrom, "DD/MM/YYYY HH:mm:ss").toDate();
            } else {
              delete y.validFrom;
            }
          }
        })
      }

      if (!x.hasOwnProperty('tags')) {
        x.tags = ''
      } else {
        // convert tags to lowercase
        x.tags = x.tags.toLowerCase()
        // remove all spaces
        x.tags = x.tags.replace(/\s/g, '')
      }
    })
    newData.totalReward = totalTimeReward;
    return newData;
  }

  const save = async () => {
    setSavingStamp(true)
    // console.log(doneList);

    let newData = _.cloneDeep(data)
    newData.subs = _.cloneDeep(stamp)
    let castedData = castData(newData)

    // Recalculate totalReward
    let totalReward = 0;

    console.log(castedData)
    castedData.subs.forEach((x) => {
      /* if (x.hasOwnProperty("countUp") && x.countUp == 1) {
        // console.log('countUp task found, skip reset time for ' + x.sname)
        // totalReward += x.finish
        // console.log('x', x)
        // console.log('Total reward added', x.finish, 'from', x.sname)
        return; // we do not perform update on countUp tasks
      } */
      if (x.hasOwnProperty("initialFinish")) {
        totalReward += x.initialFinish;
      } else {
        totalReward += x.finish;
      }
      // console.log('Total reward added', x.finish, 'from', x.sname)
    });

    castedData.totalReward = totalReward;


    await setDocx(docx(db, "subtasks", dailySn), castedData);

    // save doneList if there is any
    if (doneList.length > 0) {
      await saveDoneList()
      
    }

    if (doneListForSubs.length > 0) {
      await saveDoneListForSubs()
    }

    if (doneList.length > 0 || doneListForSubs.length > 0)
    {
      await saveSubsubHistory()
    }

    await loadStamp()
    mqttUpdate()
    
    setSavingStamp(false)
  };

  const saveDoneList = async () => {
    console.log(doneList);
    await setDocx(docx(db, "doneList", 'default'), {
      doneList: _.cloneDeep(doneList)
    });
    // alert("Done list was updated!");
    setDoneList([]);
  }

  const saveSubsubHistory = async () => {
    console.log(subsubHistoryToSync);
    await setDocx(docx(db, "subsubHistory", 'default'), {
      subsubs: _.cloneDeep(subsubHistoryToSync),
    });

    // alert("Subsub history was updated!");
    setSubsubHistoryToSync([]);
  }

  const saveDoneListForSubs = async () => {
    console.log(doneListForSubs);
    await setDocx(docx(db, "doneListForSubs", 'default'), {
      doneListForSubs: _.cloneDeep(doneListForSubs)
    });
    // alert("Done list for subs was updated!");
    setDoneListForSubs([]);
  }

  const resetTime = async () => {
    let docContent = Object.assign({}, data);
    let totalReward = 0;
    if (docContent.subs) {
      docContent.subs.forEach((s) => {
        if (s.hasOwnProperty("countUp") && s.countUp == 1) {
          // console.log('countUp task found, skip reset time for ' + s.sname)
          // totalReward += s.finish
          return; // we do not perform update on countUp tasks
        }
        if (s.hasOwnProperty("originalFinish")) {
          s.finish = s.originalFinish;
          s.initialFinish = s.finish;
        } else {
          console.log(
            "Did not update stamp reward for sub " +
            s.sname +
            " because the originalFinish field was not found"
          );
        }
        if (s.hasOwnProperty("originalTime")) {
          s.time = s.originalTime;
        } else {
          console.log(
            "Did not update stamp reward for sub " +
            s.sname +
            " because the originalTime field was not found"
          );
        }
        if (s.hasOwnProperty("initialFinish")) {
          totalReward += s.initialFinish;
        } else {
          totalReward += s.finish;
        }
      });
      docContent.totalReward = totalReward;
      // console.log('Total reward: ', docContent.totalReward);
      docContent.expiredDate = moment().add(3, "day").toDate();
      docContent.expired = moment().add(3, "day").toISOString();
      console.log("Extend expiredDate to 3 days from today");
      docContent.description = "Task plan for " + moment().format("DD/MM/YYYY");
      let newData = _.cloneDeep(docContent)
      let castedData = castData(newData) // castData uses totalReward from the state, which might not be accurate

      castedData.totalReward = totalReward; // recalculate totalReward 
      setTotalTimeReward(totalReward)

      await setDocx(docx(db, "subtasks", dailySn), castedData);
      await loadStamp()
      mqttUpdate()
      alert("Reset OK!");
    } else {
      console.log("This stamp does not contain any subsubtasks");
    }
  };

  const resetZero = () => {
    let docContent = _.clone(data);
    let totalReward = 0;
    if (docContent.subs) {
      docContent.subs.forEach((s) => {
        if (s.hasOwnProperty("countUp") && s.countUp == 1) {
          return;
        }
        s.finish = 0;
        s.initialFinish = 0;
        s.time = 0;
      });
    }
    totalReward = 0;
    setData({ ...data, totalReward: totalReward, subs: docContent.subs });
    setStamp(docContent.subs);
    setOptUpdateFlag(optUpdateFlag + 1);
    // alert("Reset OK!")
  };

  useEffect(() => {
    console.log("Stamp updated");
    // Refresh the total time units label
    // alert('Stamp change')
    let totalTu = 0;
    let totalReward = 0;
    stamp.forEach((s) => {
      if (s.hasOwnProperty('countUp') && s.countUp == 1) {
        return; // skip countUp tasks
      }
      if (s.time) {
        if (isNumber(s.time)) {
          totalTu += Number(s.time);
        }
      }
      if (s.initialFinish) {
        if (isNumber(s.initialFinish)) {
          totalReward += Number(s.initialFinish);
        }
      } else {
        if (isNumber(s.finish)) {
          totalReward += Number(s.finish);
        }
      }
    });
    setTotalTime(totalTu);
    setTotalTimeReward(totalReward);
  }, [stamp, optUpdateFlag]);

  const handleCountUpCheckbox = (sn, checked) => {
    let newStamp = _.cloneDeep(stamp);
    // find the index of property sn in newStamp
    let index = newStamp.findIndex(x => x.sn === sn);

    if (checked) {
      newStamp[index].countUp = 1
      newStamp[index].time = 0
      newStamp[index].finish = payrate
      newStamp[index].initialFinish = payrate
      newStamp[index].originalFinish = payrate
      newStamp[index].originalTime = 0
    } else {
      if (newStamp[index].hasOwnProperty('countUp')) {
        delete newStamp[index].countUp
      }
    }
    setStamp(newStamp);
    setOptUpdateFlag(optUpdateFlag + 1);
  }

  const getRemainingDays = (date) => {
    if (!date) {
      return ''
    }
    try {
      let today = moment();
      let expiry = moment(date, "DD/MM/YYYY HH:mm:ss");
      let diff = expiry.diff(today, 'days')
      return diff + 'd';
    } catch (error) {
      return '?'
    }
  }

  // This function is the same as the above function, except that it returns the number of days instead of a string
  const getRemainingDaysInNumber = (date) => {
    try {
      let today = moment();
      let expiry = moment(date, "DD/MM/YYYY HH:mm:ss");
      // console.log(date)
      let diff = expiry.diff(today, 'days')
      // console.log(diff)
      return diff;
    } catch (error) {
      return -1
    }
  }

  const handleValidFromDateChange = (sn, date) => {
    if (viewOption != 'all') {
      return // do not allow changing expiry date if viewOption is not 'all'
    }
    let newStamp = _.cloneDeep(stamp);
    // find the index of property sn in newStamp
    let index = newStamp.findIndex(x => x.sn === sn);
    // if date contains the string 'd', it means that the user has entered the number of days
    let xdString = date
    if (xdString.indexOf('d') > -1) {
      try {
        // remove the 'd' character
        xdString = xdString.replace('d', '')
        // convert to number
        let xd = Number(xdString)
        // convert to Date
        let validFromDate = moment().add(xd, 'days').set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0).format("DD/MM/YYYY HH:mm:ss");
        newStamp[index].validFromDate = validFromDate
      } catch (error) {
        newStamp[index].validFromDate = xdString
      }
    } else {
      newStamp[index].validFromDate = date
    }
    setStamp(newStamp);
    setOptUpdateFlag(optUpdateFlag + 1);
  }

  const handleExpiryDateChange = (sn, date) => {
    if (viewOption != 'all') {
      return // do not allow changing expiry date if viewOption is not 'all'
    }
    let newStamp = _.cloneDeep(stamp);
    // find the index of property sn in newStamp
    let index = newStamp.findIndex(x => x.sn === sn);
    // if date contains the string 'd', it means that the user has entered the number of days
    let xdString = date
    if (xdString.indexOf('d') > -1) {
      try {
        // remove the 'd' character
        xdString = xdString.replace('d', '')
        // convert to number
        let xd = Number(xdString)
        // convert to Date
        let expiryDate = moment().add(xd, 'days').set('hour', 2).set('minute', 0).set('second', 0).set('millisecond', 0).format("DD/MM/YYYY HH:mm:ss");
        newStamp[index].expiryDate = expiryDate
      } catch (error) {
        newStamp[index].expiryDate = xdString
      }
    } else {
      newStamp[index].expiryDate = date
    }
    setStamp(newStamp);
    setOptUpdateFlag(optUpdateFlag + 1);
  }

  const toFixed = (number, precision) => {
    try {
      return Number(number).toFixed(precision);
    } catch (error) {
      return "N/A";
    }
  }

  const roundToSecondDecimal = (number) => {
    try {
      return Number(number).toFixed(2);
    } catch (error) {
      return "N/A";
    }
  }


  const makeOriginal = () => {
    let newStamp = _.cloneDeep(stamp);
    newStamp.forEach((s) => {
      if (s.hasOwnProperty("countUp") && s.countUp == 1) {
        return; // skip countUp tasks
      }
      s.originalTime = s.time;
      s.originalFinish = s.finish;
      s.initialFinish = Number(s.finish);
    });
    setStamp(newStamp);
    setOptUpdateFlag(optUpdateFlag + 1);
  }

  const filterStampByViewOption = (viwOption, stmp) => {
    console.log("filterStampByViewOption")
    //console.log(viwOption, stmp)
    if (!stmp || stmp.length === 0 || !viwOption) {
      return []
    }
    if (viwOption === "all") {
      return stmp
    }
    else if (viwOption === "notpostponed") {
      return stmp.filter((s) => {
        if (!s.validFromDate) {
          return true
        }
        try {
          let validFr = moment(s.validFromDate, "DD/MM/YYYY HH:mm:ss")
          return validFr <= moment()
        } catch (error) {
          console.log(error)
        }
      })
    } else if (viwOption === "cart") {
      return stmp.filter(s => s.finish > 0)
    }
  }

  const getHoursMinutesFromMinutes = (minutes) => {
    let hours = Math.floor(minutes / 60)
    let mins = minutes % 60
    if (mins < 10) {
      mins = '0' + mins
    }
    return hours + ':' + mins
  }

  const starSubSub = (sn1, sn2) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let indexOfSubSub = stamp[indexOfSub]["subsubs"].findIndex(
      (x) => x.sn == sn2
    );
    let newStamp = Object.assign([], stamp);
    let subsubDescription = stamp[indexOfSub]["subsubs"][indexOfSubSub]["name"]

    // if subsubDescription begins with '***'
    if (subsubDescription.indexOf('***') == 0) {
      // remove the stars
      stamp[indexOfSub]["subsubs"][indexOfSubSub]["name"] = subsubDescription.replace('***', '')
    } else if (subsubDescription.indexOf('**') == 0) {
      // make it three stars
      stamp[indexOfSub]["subsubs"][indexOfSubSub]["name"] = subsubDescription.replace('**', '***')
    } else if (subsubDescription.indexOf('*') == 0) {
      // make it two stars
      stamp[indexOfSub]["subsubs"][indexOfSubSub]["name"] = subsubDescription.replace('*', '**')
    } else {
      // make it one star
      stamp[indexOfSub]["subsubs"][indexOfSubSub]["name"] = '*' + subsubDescription
    }
    setStamp(newStamp);
  }

  const loadTemplates = () => {
    let mDailyTemplates = []

    const documentSnapshot = getDocx(docx(db, "templates", "daily"));

    console.log('Dokument snapshot', documentSnapshot)

    documentSnapshot.then((doc) => {
      console.log('Doc', doc)
      if (doc.exists()) {
        let data = doc.data(); // data looks like [{name: 'xxx', subs: [{sname : 'xx', time: x, finish: x}, {sname:...}]}]
        // console.log(data)
        if (data.hasOwnProperty('subs')) {
          setDailyTemplates(data.subs)
        }
      } else {
        console.log("Document does not exist");
      }
    });
  }

  const saveTemplate = () => {
    let newTemplate = []
    stamp.forEach((s) => {
      let newSub = _.cloneDeep(s)
      if (newSub.hasOwnProperty('validFromDate')) {
        newSub.validFromDate = moment(newSub.validFromDate, "DD/MM/YYYY HH:mm:ss").toISOString()
      }
      if (newSub.hasOwnProperty('expiryDate')) {
        newSub.expiryDate = moment(newSub.expiryDate, "DD/MM/YYYY HH:mm:ss").toISOString()
      }
      newTemplate.push(newSub)
    })
    // Update the document for DB
    let newDailyTemplates = _.cloneDeep(dailyTemplates)
    // Check if the template name already exists
    let index = newDailyTemplates.findIndex(x => x.name.toLowerCase() == nameDailyTemplate.toLowerCase())
    if (index > -1) {
      // The template already exists, update it
      newDailyTemplates[index].subs = newTemplate
    } else {
      // The template does not exist, add it
      newDailyTemplates.push({
        name: nameDailyTemplate,
        subs: newTemplate
      })
    }

    // Update the database
    setDocx(docx(db, "templates", "daily"), {
      subs: newDailyTemplates
    }).then(() => {
      alert('Template saved')
      loadTemplates()
    })

  }

  const appendFromTemplate = () => {
    dailyTemplates.forEach((t) => {
      if (t.name == selectedDailyTemplate) {
        // The template is t
        let newStamp = _.cloneDeep(stamp);
        // console.log(newStamp)
        t.subs.forEach((ts) => {
          // For each subtask in the template, check if it is already in the stamp
          let index = newStamp.findIndex(x => x.sname.toLowerCase() == ts.sname.toLowerCase())
          console.log('TS: ')
          console.log(ts)
          try {
            if (ts.hasOwnProperty('validFromDate')) {
              // Check if ts.validFromDate is an ISO string
              if (ts.validFromDate.indexOf('T') > -1) {
                ts.validFromDate = moment(ts.validFromDate).format('DD/MM/YYYY HH:mm:ss')
              }
            }
            if (ts.hasOwnProperty('expiryDate')) {
              // Check if ts.expiryDate is an ISO string
              if (ts.expiryDate.indexOf('T') > -1) {
                ts.expiryDate = moment(ts.expiryDate).format('DD/MM/YYYY HH:mm:ss')
              }
            }
          } catch (error) {
            console.log('Cannot format date')
            console.log(error)
          }
          if (index > -1) {
            // This subtask is already in the stamp
            console.log('Found subtask', ts.sname, 'in the stamp')
            
            let oldSubs = []

            console.log('New stamp', newStamp)
            
            if (newStamp[index].hasOwnProperty('subsubs') && ts.hasOwnProperty('subsubs'))
            {
              oldSubs = _.cloneDeep(newStamp[index].subsubs)
              newStamp[index] = _.cloneDeep(ts) // replace the subtask in the stamp with the subtask in the template
              oldSubs.forEach((os) => {
                let indexx = newStamp[index].subsubs.findIndex(x => x.name == os.name)
                if (indexx == -1) {
                  newStamp[index].subsubs.push(os)
                }
              })
            }

          } else {
            // This subtask is not in the stamp
            newStamp.push(_.cloneDeep(ts))
          }
        })
        console.log(newStamp)
        setStamp(newStamp)
      }
    })
  }

  const transferTemplate = () => {
    console.log('Selected template', selectedDailyTemplate)
    console.log('Daily templates', dailyTemplates)
    dailyTemplates.forEach((t) => {
      if (t.name == selectedDailyTemplate) {
        // The template is t
        let newStamp = _.cloneDeep(stamp);
        newStamp.forEach((s) => {
          // For each subtask in stamp, check if it is in the template
          let index = t.subs.findIndex(x => x.sname.toLowerCase() == s.sname.toLowerCase())
          if (index > -1) {
            // console.log('Found subtask', s.sname, 'in the template')
            // This subtask is in the template
            // Transfer the time and pay
            s.time = Number(t.subs[index].time)
            s.finish = Number(t.subs[index].finish)
            s.initialFinish = Number(t.subs[index].finish)
            // console.log('New time and pay', s.time, s.finish)
          } else {
            s.time = 0
            s.finish = 0
            s.initialFinish = 0
          }
        })
        // console.log(newStamp)
        setStamp(newStamp)
      }
    })
  }

  const deleteTemplate = () => {
    if (selectedDailyTemplate == '') {
      alert('Please choose a template to delete')
      return
    }
    let newDailyTemplates = _.cloneDeep(dailyTemplates)
    let index = newDailyTemplates.findIndex(x => x.name == selectedDailyTemplate)
    if (index > -1) {
      newDailyTemplates.splice(index, 1) // remove the template
      setDocx(docx(db, "templates", "daily"), {
        subs: newDailyTemplates
      }).then(() => {
        alert('Template deleted')
        setNameDailyTemplate('')
        setSelectedDailyTemplate('')
        loadTemplates()
      })
    }
  }

  const promoteSubSub = (sname, ssname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    const indexx = stampCopy[index].subsubs.findIndex(x => x.name == ssname)
    // console.log('Promote subsub', ssname, 'from', sname)
    // console.log('Index', index, indexx)
    if (indexx > 0) {
      let temp = stampCopy[index].subsubs[indexx]
      // console.log('Temp', temp)
      stampCopy[index].subsubs[indexx] = stampCopy[index].subsubs[indexx - 1]
      stampCopy[index].subsubs[indexx - 1] = temp
      setStamp(stampCopy)
    }
  }

  const promoteSubSubToTop = (sname, ssname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    const indexx = stampCopy[index].subsubs.findIndex(x => x.name == ssname)
    // console.log('Promote subsub to top', ssname, 'from', sname)
    // console.log('Index', index, indexx)
    if (indexx > 0) {
      let temp = stampCopy[index].subsubs[indexx]
      // console.log('Temp', temp)
      stampCopy[index].subsubs.splice(indexx, 1)
      stampCopy[index].subsubs.unshift(temp)
      setStamp(stampCopy)
    }
  }

  const demoteSubSub = (sname, ssname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    const indexx = stampCopy[index].subsubs.findIndex(x => x.name == ssname)
    // console.log('Demote subsub', ssname, 'from', sname)
    // console.log('Index', index, indexx)
    if (indexx < stampCopy[index].subsubs.length - 1) {
      let temp = stampCopy[index].subsubs[indexx]
      // console.log('Temp', temp)
      stampCopy[index].subsubs[indexx] = stampCopy[index].subsubs[indexx + 1]
      stampCopy[index].subsubs[indexx + 1] = temp
      setStamp(stampCopy)
    }
  }

  const promoteSub = (sname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    console.log('Promote sub', sname)
    console.log('Index', index)
    if (index > 0) {
      let temp = stampCopy[index]
      // console.log('Temp', temp)
      stampCopy[index] = stampCopy[index - 1]
      stampCopy[index - 1] = temp
      setStamp(stampCopy)
    }
  }

  const demoteSub = (sname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    // console.log('Demote sub', sname)
    // console.log('Index', index)
    if (index < stampCopy.length - 1) {
      let temp = stampCopy[index]
      // console.log('Temp', temp)
      stampCopy[index] = stampCopy[index + 1]
      stampCopy[index + 1] = temp
      setStamp(stampCopy)
    }
  }

  const promoteSubToTop = (sname) => {
    const stampCopy = _.cloneDeep(stamp)
    const index = stampCopy.findIndex(x => x.sname == sname)
    // console.log('Promote sub to top', sname)
    // console.log('Index', index)
    if (index > 0) {
      let temp = stampCopy[index]
      stampCopy.splice(index, 1)
      stampCopy.unshift(temp)
      setStamp(stampCopy)
    }
  }

  const syncBalanceAndReload = async () => {
    await syncBalance()
    // Refresh the page
    window.location.reload()
  }

  return (
    <div className="AppRootDesktop">
      <div className='AppRootInnerOverlay'>
        <div className="StickyEdit">
          <div style={{ flex: '0 0 auto' }}>
            <div>Time units: {totalTime.toFixed(0)} / {getHoursMinutesFromMinutes(totalTime * 25)}</div>
            <div>Init pay: {totalTimeReward.toFixed(2)}</div>
          </div>
          <div style={{ flex: '1 0 auto' }}>

          </div>
          <div style={{ flex: '0 0 auto', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <div>
              {savingStamp && <Spinner style={{marginRight: 4}} animation="border" variant="primary" />}
            </div>
            {!savingStamp && <div>
              <Button
                variant="success"
                onClick={() => {
                  save();
                }}
              >
                <AiFillSave></AiFillSave> SAVE
              </Button>
            </div>}
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  resetTime();
                }}
              >
                <BiReset></BiReset>
              </Button>
            </div>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  resetZero();
                }}
              >
                <BiReset></BiReset>
              </Button>
            </div>
            <div>
              <Button
                variant="success"
                onClick={() => {
                  syncBalanceAndReload();
                }}
              >
                <FaSync></FaSync>
              </Button>
            </div>

          </div>
        </div>

        {width > 1200 && <div className="AllTasksSummary">
          <div className="AllTasksSummaryHeader">CART</div>
          {stamp.filter((s) => s.finish > 0).map((s) => {
            return (
              <div className="AllTasksSummaryBlock">
                <div className="AllTasksSummaryTitle">{s.sname}</div>
                <div className="AllTasksSummaryBlank"></div>
                <div className="AllTasksSummaryTime">{toFixed(s.time, 2)} / {toFixed(s.finish, 2)}</div>
              </div>
            );
          })}
        </div>}

        <div className="BodyEdit" style={{paddingTop: 90}}>
          <div className="ViewOptions">
            <Button variant={`${viewOption === 'all' ? 'primary' : 'outline-primary'}`} onClick={() => { setViewOption("all") }}>ALL</Button>
            <Button variant={`${viewOption === 'notpostponed' ? 'danger' : 'outline-danger'}`} onClick={() => { setViewOption("notpostponed") }}>NOT POSTPONED</Button>
            <Button variant={`${viewOption === 'cart' ? 'success' : 'outline-success'}`} onClick={() => { setViewOption("cart") }}>CART</Button>
          </div>
          <div className="TemplateControls">
            <div style={{ display: templateExpanded ? "none" : "flex" }} onClick={() => setTemplateExpanded(!templateExpanded)}>
              <div style={{ marginRight: 4 }}><FaChevronDown></FaChevronDown></div> Templates
            </div>
            <div style={{ display: templateExpanded ? "flex" : "none", flexDirection: 'column' }}>
              <div>Template Name</div>
              <div>
                <Form.Select aria-label="Choose a template" onChange={(e) => { setSelectedDailyTemplate(e.target.value); setNameDailyTemplate(e.target.value) }}>
                  <option>Choose a template</option>
                  {dailyTemplates.map((t) => {
                    return <option value={t.name}>{t.name}</option>
                  })}
                </Form.Select>
              </div>
              <div>
                <div>
                  Save As
                </div>
                <div>
                  <input type="text" value={nameDailyTemplate} onChange={(e) => { setNameDailyTemplate(e.target.value) }}></input>
                </div>
              </div>
              <div className="TemplateControlButtons">
                <Button variant="primary" onClick={() => { transferTemplate() }}><FaAngleRight></FaAngleRight> Transfer</Button>
                <Button variant="primary" onClick={() => { appendFromTemplate() }}><FaPlus></FaPlus> Append</Button>
                <Button variant="success" onClick={() => { saveTemplate() }}><MdSave></MdSave> Override</Button>
                <Button variant="danger" onClick={() => { deleteTemplate() }}><MdDelete></MdDelete> Delete</Button>
              </div>
            </div>
          </div>
          {!enableDelete && <div className="SyncWarning">
            <div>CAUTION</div>
            <div>Unsynchronized reports are present. Please <a href="#" onClick={() => syncBalanceAndReload()}>sync</a> with the app.</div>
          </div>}
          <div style={{ height: 12 }}></div>

          {filterStampByViewOption(viewOption, stamp).map((sub) => (

            <div className={`${getRemainingDaysInNumber(sub.validFromDate) > 0 ? 'SubTaskGrayedOut' : ''} ${sub.finish > 0 ? 'SubTaskSelect' : ''} SubTaskEdit`}>
              <div>
                <Button
                  variant={sub.time == 0 ? "danger" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 0)}
                >
                  0
                </Button>
                <Button
                  variant={sub.time == 1 ? "primary" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 1)}
                >
                  1
                </Button>
                <Button
                  variant={sub.time == 2 ? "primary" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 2)}
                >
                  2
                </Button>
                <Button
                  variant={sub.time == 3 ? "primary" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 3)}
                >
                  3
                </Button>
                <Button
                  variant={sub.time == 4 ? "primary" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 4)}
                >
                  4
                </Button>
                <Button
                  variant={sub.time == 5 ? "primary" : "outline-primary"}
                  onClick={() => setTimeByButton(sub.sn, 5)}
                >
                  5
                </Button>
              </div>
              <Row className="Field SubTaskTitleEdit">
                <Col xs={6} md={6}>Name:{" "}</Col>
                <Col xs={6} md={6}><input
                  type="text"
                  value={sub.sname}
                  onChange={(e) => {
                    handleTextChange(e, "sname", sub.sn);
                  }}
                ></input></Col>
              </Row>
              <Row className="Field">
                <Col xs={6} md={6}>
                  Time units:{" "}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.time}
                    onChange={(e) => {
                      handleTextChange(e, "time", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>
              <Row className="Field">
                <Col xs={6} md={6}>
                  Pay: ({sub.hasOwnProperty('initialFinish') ? sub.initialFinish : 'N/A'})
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.finish}
                    onChange={(e) => {
                      handleTextChange(e, "pay", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>

              <Row className="Field">
                <Col xs={6} md={6}>
                  Bonus coeff:{" "}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.bonusCoeff}
                    onChange={(e) => {
                      handleTextChange(e, "bonusCoeff", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>
              <Row className="Field">
                <Col xs={6} md={6}>
                  Orig time:{" "}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.originalTime}
                    onChange={(e) => {
                      handleTextChange(e, "originalTime", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>
              <Row className="Field">
                <Col xs={6} md={6}>
                  Orig pay:{" "}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.originalFinish}
                    onChange={(e) => {
                      handleTextChange(e, "originalFinish", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>
              <Row className="Field">
                <Col xs={6} md={6}>
                  Count up:
                </Col>
                <Col xs={6} md={6} >

                  <Form.Check type='switch'
                      id={`countUpSwitch-${sub.sn}`}
                      label=''
                      onChange={(e) => handleCountUpCheckbox(sub.sn, e.target.checked)}
                      checked={sub.hasOwnProperty('countUp') && sub.countUp == 1}
                  />
                </Col>
              </Row>

              <Row className="Field">
                <Col xs={6} md={6}>
                  Valid from: {getRemainingDays(sub.validFromDate)}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.validFromDate ? sub.validFromDate : ''}
                    onChange={(e) => { handleValidFromDateChange(sub.sn, e.target.value) }}
                  ></input>
                </Col>
              </Row>

              <Row className="Field">
                <Col xs={6} md={6}>
                  Expiry date: {getRemainingDays(sub.expiryDate)}
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.expiryDate ? sub.expiryDate : ''}
                    onChange={(e) => { handleExpiryDateChange(sub.sn, e.target.value) }}
                  ></input>
                </Col>
              </Row>

              <Row className="Field">
                <Col xs={6} md={6}>
                  Min Minutes for Feedback Inquiry:
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.allowFeedback ? sub.allowFeedback : ''}
                    onChange={(e) => { handleTextChange(e, "allowFeedback", sub.sn) }}
                  ></input>
                </Col>
              </Row>

              <Row className="Field">
                <Col xs={6} md={6}>
                  Tags:
                </Col>
                <Col xs={6} md={6}>
                  <input
                    type="text"
                    value={sub.tags ? sub.tags : ''}
                    onChange={(e) => {
                      handleTextChange(e, "tags", sub.sn);
                    }}
                  ></input>
                </Col>
              </Row>

              <div>
                {sub.subsubs.map((ss) => (
                  <div className={`${
                    ss.validFrom != null && moment(ss.validFrom, 'DD/MM/YYYY HH:mm:ss').isAfter(moment()) ? 'SubSubTaskEditGrayedOut' : 'SubSubTaskEdit'
                  }`}>
                    <div>
                      <input
                        type="text"
                        value={ss.name}
                        onChange={(e) =>
                          handleSubTextChange(e, sub.sn, ss.sn, "name")
                        }
                      ></input>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={ss.finish}
                        onChange={(e) =>
                          handleSubTextChange(e, sub.sn, ss.sn, "finish")
                        }
                      ></input>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={ss.validFrom}
                        onChange={(e) =>
                          handleSubTextChange(e, sub.sn, ss.sn, "validFrom")
                        }
                        placeholder="Valid from (DMY HMS)"
                      ></input>
                    </div>
                    <Row className="SubSubButtonControls">
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="danger"
                          onClick={() => {
                            deleteSubSub(sub.sn, ss.sn);
                          }}
                        >
                          <AiFillDelete></AiFillDelete>
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={() => {
                            addToDoneList(sub.sn, ss.sn, ss.name, ss.finish);
                          }}
                        >
                          <AiFillCheckCircle></AiFillCheckCircle>
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="primary"
                          onClick={() => { starSubSub(sub.sn, ss.sn) }}
                        ><AiFillStar></AiFillStar></Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="primary"
                          onClick={ () => {
                            promoteSubSub(sub.sname, ss.name)
                          }}
                        >
                          <FaArrowUp></FaArrowUp>
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="primary"
                          onClick={ () => {
                            demoteSubSub(sub.sname, ss.name)
                          }}
                        >
                          <FaArrowDown></FaArrowDown>
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="primary"
                          onClick={ () => {
                            promoteSubSubToTop(sub.sname, ss.name)
                          }}
                        >
                          !
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 1)
                          }}
                        >
                          1
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 2)
                          }}
                        >
                          2
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 3)
                          }}
                        >
                          3
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 7)
                          }}
                        >
                          7
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 10)
                          }}
                        >
                          0
                        </Button>
                      </Col>
                      <Col xs={2} sm={3} md={1}>
                        <Button
                          variant="success"
                          onClick={ () => {
                            addToDoneListWithRecall(sub.sn, ss.sn, ss.name, ss.finish, 14)
                          }}
                        >
                          4
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              <div className="SubtaskActionButtons" style={{ display: "flex", flexDirection: "row" }}>
                <Row style={{ flex: 1 }}>
                  <Col xs={6} sm={6} md={2}>
                    <div>
                      <Button
                        variant="success"
                        onClick={() => {
                          addSubSub(sub.sn);
                        }}
                      >
                        <IoAdd></IoAdd> ADD
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={2}>
                    <div>
                      {(enableDelete || true) && <Button
                        variant="danger"
                        onClick={() => {
                          deleteSub(sub.sn);
                        }}
                      >
                        <AiFillDelete></AiFillDelete> DELETE
                      </Button>}
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={2}>
                    <Button
                      variant="success"
                      onClick={() => {
                        addToDoneListForSubs(sub.sn);
                      }}
                    >
                    <AiFillCheckCircle></AiFillCheckCircle> Done
                  </Button>
                  </Col>
                  <Col xs={6} sm={6} md={2}>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          promoteSub(sub.sname)
                        }}
                      >
                        <FaArrowUp></FaArrowUp>
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={2}>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          demoteSub(sub.sname)
                        }}
                      >
                        <FaArrowDown></FaArrowDown>
                      </Button>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={2}>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          promoteSubToTop(sub.sname)
                        }}
                      >
                        <FaArrowUp></FaArrowUp> TOP
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>

            </div>

          ))}
          <Row className="BottomButtons" style={{ display: "flex", flexDirection: "row" }}>
            <Col xs={12} sm={6} md={3}>
              <Button
                variant="success"

                onClick={() => {
                  addSub();
                }}
              >
                <IoAdd></IoAdd> ADD
              </Button>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Button variant="primary" onClick={() => { makeOriginal() }}>MAKE ORIGINAL</Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Edit;
