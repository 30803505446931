import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Habits.css'
import { useDispatch } from 'react-redux'
import {AiOutlineCaretDown} from 'react-icons/ai'
import {setNetworkOk} from './calendarReducers'
import ellipsizeString from './EllipsizeString';

import { setTodosOrHabitsAvailable } from './calendarReducers';

import _ from 'lodash'
import token from './Token';

function Habits()
{

    const dispatch = useDispatch() 

    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
          const stickyValue = window.localStorage.getItem(key);
          return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
        });
        useEffect(() => {
          window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }

    // Redux states
    const [habits, setHabits] = useState([])
    const [habitLength, setHabitLength] = useState(0)
    const [habitTitle, setHabitTitle] = useStickyState('', 'habitTitle')
    const [savedHabitTitle, setSavedHabitTitle] = useStickyState('', 'savedHabitTitle') // todoTitle saved in local storage
    const [showAllHabits, setAllHabits] = useState(false)
    const [timerHandler, setTimerHandler] = useState(null)
    const [currentTime, setCurrentTime] = useState(moment())
    const [lastUpdated, setLastUpdated] = useState(moment())

    // For ChatGPT like typing effect
    const [displayResponse, setDisplayResponse] = useState('');
    const [completedTyping, setCompletedTyping] = useState(false);
    // For automatic rolling of todos
    const [habitIndex, setHabitIndex] = useState(0)

    async function loadHabitsFromNewServer () {
        try {
            setLastUpdated(moment().add(5, 'minute'))
            // fetch get https://paymemobile.fr/habits
            const response = await fetch('https://paymemobile.fr/habits?token='+token)
            const json = await response.json()
            console.log('habits is ', json)
            let mHabits = _.cloneDeep(json)

            // Filter keep habits that are less than 24 hours away from nextEvaluation
            mHabits = mHabits.filter((h) => {
                let nextEvaluation = moment(h.nextEvaluation)
                if (h.evaluationFrequency.hourOfDay == null || h.evaluationFrequency.minuteOfHour == null)
                {
                    h.evaluationFrequency.hourOfDay = 6
                    h.evaluationFrequency.minuteOfHour = 0
                }
                nextEvaluation.set({hour: h.evaluationFrequency.hourOfDay, minute: h.evaluationFrequency.minuteOfHour, second: 0, millisecond: 0})
                if (nextEvaluation.isBefore(moment().add(24, 'hour')))
                {
                    return true
                } else {
                    return false // should be false
                }
            })

            // console.log('mHabits is ', mHabits)
            // console.log(mHabits)

            // setHabits(mHabits)

            // Find the habit title
            let habitTitleFound = false
            mHabits.forEach((h) => {
                if (h.name == savedHabitTitle)
                {
                    habitTitleFound = true
                }
            })

            // If the habit title is not found, set it to the first habit
            if (!habitTitleFound)
            {
                if (mHabits.length > 0)
                {
                    // console.log('Setting habitTitle to ' + mHabits[0].name)
                    setHabitTitle(mHabits[0].name)
                }
            }

            // console.log(mHabits)
            
            // Reorder the habits
            mHabits = mHabits.sort((a,b) => a.order - b.order)
            setHabits(mHabits)
            setHabitLength(mHabits.length)
            if (mHabits.length > 0)
            {
                dispatch(setTodosOrHabitsAvailable({todosOrHabitsAvailable: true}))
            }
        } catch (e) {
            console.log(e)
            dispatch(setNetworkOk({networkOk: false}))
            setLastUpdated(moment().add(5, 'minute'))
        }
    }

    // async function loadHabits () {
    //     try {
    //         const querySnapshot = await getDocs(query(collection(db, "habits")))
    //         let mHabits = []
    //         if (querySnapshot.size == 0)
    //         {
    //             // Do not override if no data is received (possibly due to network failure)
    //             setLastUpdated(moment().add(5, 'minute'))
    //             // dispatch(setNetworkOk({networkOk: false}))
    //             return 
    //         }
    //         querySnapshot.forEach((doc) => {
    //             let data = doc.data()
    //             let habitLastDone = data.lastDone.toDate()
    //             data.lastDone = habitLastDone.toISOString()
    //             // adjust lastDone to 2AM
    //             // let habitLastDone = moment(data.lastDone.toDate())
    //             // let newHabitLastDone = habitLastDone.add(-2, 'hour').set({hour: 2, minute: 0, second: 0, millisecond: 0})
    //             // data.lastDone = newHabitLastDone.toDate().toISOString()
    //             mHabits.push(data)
    //         })
            
    //         // Filter out habits that has already been done
    //         mHabits = mHabits.filter((h) => {
    //             let habitLastDone = moment(h.lastDone)
    //             // console.log(h.name, ": ", habitLastDone.add(h.autoReset - 1, 'day').format("DD/MM/YYYY HH:mm:ss"))
    //             if (habitLastDone.add(h.autoReset - 1, 'day') < moment())
    //             {
    //                 return true // remove the habits whose lastDone is in the future
    //             } else {
    //                 return false
    //             }
    //         })


    //         // Find the habit title
    //         let habitTitleFound = false
    //         mHabits.forEach((h) => {
    //             if (h.name == habitTitle)
    //             {
    //                 habitTitleFound = true
    //             }
    //         })

    //         // If the habit title is not found, set it to the first habit
    //         if (!habitTitleFound)
    //         {
    //             if (mHabits.length > 0)
    //             {
    //                 console.log('Setting habitTitle to ' + mHabits[0].name)
    //                 setHabitTitle(mHabits[0].name)
    //             }
    //         }

    //         // console.log(mHabits)
            
    //         // Reorder the habits
    //         mHabits = mHabits.sort((a,b) => a.order - b.order)
    //         setHabits(mHabits)
    //         setHabitLength(mHabits.length)
    //         setLastUpdated(moment().add(5, 'minute'))
    //     } catch (e) {
    //         console.log(e)
    //         setLastUpdated(moment().add(5, 'minute'))
    //         dispatch(setNetworkOk({networkOk: false}))
    //     }
    // }

    // Periodically load habits from the server
    useEffect(() => {
        //console.log('Current time: ', currentTime)
        //console.log('Update time: ', lastUpdated)
        if (moment(currentTime) > lastUpdated)
        {
            console.log('Load habits...')
            loadHabitsFromNewServer()
            setLastUpdated(moment().add(5, 'minute'))
        }
    }, [currentTime, lastUpdated])

    // For ChatGPT like typing effect
    useEffect(() => {
        setCompletedTyping(false);
      
        let i = 0;
        const stringResponse = ellipsizeString(habitTitle)
      
        const intervalId = setInterval(() => {
          setDisplayResponse(stringResponse.slice(0, i))
      
          i++;
      
          if (i > stringResponse.length) {
            clearInterval(intervalId);
            setCompletedTyping(true);
          }
        }, 50);
      
        return () => clearInterval(intervalId);
      }, [habitTitle]);

      // Slideshow effect of Todos
    useEffect(() => {
        let indexShuffler = setInterval(() => {
            setHabitIndex((index) => {
                // console.log('Index is ' + index)
                return index + 1
            })
        }, 10000)

        return () => {
            clearInterval(indexShuffler)
        }
    }, [])

    // Update todoTitle when todoIndex changes (every 10 seconds due to the timer indexShuffler)
    useEffect(() => {
        // console.log('savedHabitTitle is ' + savedHabitTitle)
        // console.log('habitTitle is ' + habitTitle)
        // console.log('habitIndex is ' + habitIndex)
        // console.log('savedTodoTitle is ' + savedTodoTitle)
        // console.log('todoTitle is ' + todoTitle)
        const habitsLength = habits.length // todoLength and state's todoLength are not synchronized
        // console.log('habitsLength is ' + habitsLength)
        if (habitsLength > 0)
        {
            if (savedHabitTitle == '')
            {
                // There is not saved habitTitle, so we can roll the habitTitle
                if (habitsLength > 1)
                {
                    // If there are more than 1 habit, then autoplays the habits
                    // console.log('Setting habitTitle to ' + habits[0].name)
                    setHabitTitle(habits[habitIndex % habitsLength].name)
                } else {
                    // If there is only 1 habit, then display it
                    // This code is to prevent unnecessary animation
                    if (habitTitle !== habits[0].name)
                    {
                        // console.log('Setting habitTitle to ' + habits[0].name)
                        setHabitTitle(habits[0].name)
                    }
                }
            } else {
                // There is a savedHabitTitle, so we should display it
                if (habitTitle !== savedHabitTitle)
                {
                    // console.log('Setting habitTitle to ' + savedHabitTitle)
                    setHabitTitle(savedHabitTitle)
                    // console.log('Setting todoTitle to ' + savedTodoTitle)
                }
            }
            // console.log('Setting todoTitle to ' + allTodos[todoIndex % todoLength].content)
        }
    }, [habitIndex, habitTitle, savedHabitTitle, habits])

    useEffect(() => {
        loadHabitsFromNewServer()
        let t = setTimerHandler(setInterval(() => {
            setCurrentTime(moment().toDate().toISOString())
        }, 20000))
        
        return () => {
            clearInterval(t)
        }
    }, [])

    const toggleAllHabits = () => {
        setAllHabits(!showAllHabits)
    }

    const saveHabitTitle = async (habitTitle) => {
        if (savedHabitTitle == habitTitle)
        {
            setSavedHabitTitle('')
        } else {
            setSavedHabitTitle(habitTitle)
        }
    }

    useEffect(() => {
        // Trigger reflow in order to update shimmering effect starting and stopping position when a component appears or disappears
        // In this case: this <Todo> component
        if (window.handleOverflow) {
            window.handleOverflow() // in Home.js
        }
    }, [habitLength])

    const countHabit = async (habitId) => {
        // make a get request to https://paymemobile.fr/countHabit?token=xxx&id=xxx
        const response = await fetch('https://paymemobile.fr/countHabit?token='+token+'&id='+habitId)
        const json = await response.json()
        // load the habits again
        await loadHabitsFromNewServer()
    }


    if (habits.length > 0)
    {
        return(
            <div style={{flex: 1}}>
                <div className='TextBarHabit'>
                    <div className='TopHabit' onClick={() => toggleAllHabits()}>
                        {habits.length > 0 ? <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', height: 30, alignItems: 'center'}}><div className='HabitHighlight'>{displayResponse} {habitLength > 1 ? '+' + (habitLength - 1) : ''}</div> <div style={{marginTop: -3, marginLeft: 3}}>{habitLength > 1 ? <AiOutlineCaretDown></AiOutlineCaretDown> : <></>}</div></div> : <></>} 
                    </div>
                    
                        {showAllHabits && <div className='DetailsHabits'>
                            {habits.map((h) => {
                                return(<div className='Habit' onClick={() => saveHabitTitle(h.name)} onContextMenu={(e) => { e.preventDefault(); countHabit(h._id); }}>• {h.name}</div>)
                            })}
                        </div>}
                    
                    
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default React.memo(Habits)