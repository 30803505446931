import './Splash.css';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import token from './Token'
import mqttUsername from "./MqttUsername";
import Keypad from './Keypad';
import { FaArrowLeft, FaLock, FaLockOpen } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import FireworksDisplay from './FireworksDisplay';
import { fetchBalance } from './balanceUtils';
function Splash() {
    const [time, setTime] = useState(new Date());
    const [loading, setLoading] = useState(true);

    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        });
        useEffect(() => {
            window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }

    const [locked, setLocked] = useStickyState(false, 'locked');
    const [blackBg, setBlackBg] = useState(false) // to switch to black background after 5 minutes
    const [showKeypad, setShowKeypad] = useState(false);

    // For ChatGPT like typing effect
    const [displayResponse, setDisplayResponse] = useState('');
    const [completedTyping, setCompletedTyping] = useState(false);

    const [quotes, setQuotes] = useState([])
    const [quoteSet, setQuoteSet] = useState('')
    const [quoteIndex, setQuoteIndex] = useState(0)

    const [intClocks, setIntClocks] = useState([])

    const [profilePic, setProfilePic] = useState(null)
    const [showProfilePic, setShowProfilePic] = useState(false)
    const [username, setUsername] = useState(mqttUsername)

    const [lastScreenActive, setLastScreenActive] = useState(moment().toISOString())

    // Celebrations and fireworks
    const [fireWorksOn, setFireWorksOn] = useState(false)
    const [celebrationMessage, setCelebrationMessage] = useState('')

    // Ordoscope version
    const [ordoscopeVersion, setOrdoscopeVersion] = useState('')

    const [balance, setBalance] = useState('0')

    const redirectToLogin = () => {
        if (localStorage.getItem('token') == null) {
            window.location.href = '/login';
        }
    }
    
    

    const getProfilePic = async () => {
        if (localStorage.getItem('token') == null) {
            return
        }
        try {
            console.log('Fetching profile pic')
            let response = await fetch('https://paymemobile.fr/avatar?token=' + localStorage.getItem('token'))
            if (response.status != 200) {
                console.log('Failed to fetch profile pic')
                setProfilePic(null)
                return
            }
            let data = await response.blob()
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                localStorage.setItem('profilePic', base64data);
                setProfilePic(base64data);
            };
            reader.readAsDataURL(data);
        } catch (e) {
            console.log(e)
        }
    }

    const getCloudPreferences = async () => {
        if (localStorage.getItem('token') == null) {
            return
        }
        try {
            let response = await fetch('https://paymemobile.fr/userinfo?token=' + localStorage.getItem('token'))
            let data = await response.json()
            localStorage.setItem('username', data.username)
            setUsername(data.username)
            localStorage.setItem('payrate', data.payrate)
            localStorage.setItem('timezone', data.timezone)
            localStorage.setItem('lat', data.lat)
            localStorage.setItem('lon', data.lon)
            if (data.sessionLength != null)
            {
                localStorage.setItem('sessionLength', data.sessionLength)
            }
            if (data.intClocks != null && data.intClocks.length > 0) {
                setIntClocks(data.intClocks)
            } else {
                console.log('Setting intClocks to default: ')
                setIntClocks([''])
            }

            // Handle celebrations
            // celebrations: {date: "23/06", title: "Chi Thao Birthday"}
            if (data.celebrations != null) {
                for (let i = 0; i < data.celebrations.length; i++) {
                    if (moment().format('DD/MM') == data.celebrations[i].date) {
                        setFireWorksOn(true)
                        setCelebrationMessage(data.celebrations[i].title)
                        break
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(async () => {
        document.title = 'Ordoscope Welcome';

        redirectToLogin() // Redirect to login if not logged in

        let clock = setInterval(() => {
            setTime((oldTime) => moment().toDate())
        }, 1000);

        /* // fetch get https://paymemobile.fr/quotes
        const response = await fetch('https://paymemobile.fr/quotes?token='+token);
        const data = await response.json();
        setQuotes(data.quotes) // is an array of strings

        let quoteShuffler = setInterval(() => {
            setQuoteIndex((oldQuoteIndex) => oldQuoteIndex + 1)
            console.log('Shuffling quotes')
        }, 10000) // 10 seconds */

        setLoading(true)

        await getCloudPreferences()

        await getProfilePic()

        const balance = await fetchBalance()
        setBalance(balance)

        setLoading(false)


        // Set the ordoscope version
        const urlParams = new URLSearchParams(window.location.search);
        const scope = urlParams.get('scope');
        
        if (scope === '3') {
            setOrdoscopeVersion('3');
        } else if (scope === '4') {
            setOrdoscopeVersion('4');
        } else {
            setOrdoscopeVersion('');
        }

        return () => {
            clearInterval(clock);
            // clearInterval(quoteShuffler);
        }

        
    }, []);

    useEffect(() => {
        // Everytime quoteIndex changes, we want to update the quoteSet
        if (quotes.length > 0) {
            setQuoteSet(quotes[quoteIndex % quotes.length])
        }
    }, [quoteIndex, quotes])

    // For ChatGPT like typing effect
    useEffect(() => {
        setCompletedTyping(false);

        let i = 0;
        const stringResponse = quoteSet

        const intervalId = setInterval(() => {
            setDisplayResponse(stringResponse.slice(0, i))

            i = i + 3;

            if (i > stringResponse.length) {
                clearInterval(intervalId);
                setCompletedTyping(true);
            }
        }, 50);

        return () => clearInterval(intervalId);
    }, [quoteSet]);

    // Wait more than 5 minutes: switch background to dark
    useEffect(() => {
        if (moment(lastScreenActive).add(15, 'seconds').isBefore(moment(time))) {
            if (!blackBg) {
                setBlackBg(true)
            }
        }
    }, [time, lastScreenActive, blackBg])

    if (loading) {
        return (<div className={`AppRoot`}><div className='AppRootInnerOverlay'>
            <div className='SplashScreen' style={{paddingTop: 60}}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img src={require("./glassworkspace.png")} alt='GW logo' style={{ width: 320 }}></img>
                </div>
                <div style={{ height: 48 }}>
                    <div><Spinner animation="border" variant="primary" /></div>
                </div>
            </div>
        </div>
        </div>)
    }

    if (locked && !showKeypad) {
        return (<div className={`AppRoot ${blackBg ? 'AppRootNoBg' : ''}`}><div className='AppRootInnerOverlay'>
            <div className='SplashScreen' onClick={() => {
                setShowKeypad(true);
                setLastScreenActive(moment().toISOString())
                setBlackBg(false)
            }}>

                <div className='splash-lock'>
                    <div><FaLock /></div>
                </div>
                <div className="SplashClock">
                    {intClocks.map((clock, index) => {
                        if (clock == '') {
                            return (<div className={`Clock Clock1`}>
                                <div className="Time"><span className='Hour'>{moment(time).format('H')}</span>:<span className='Minute'>{moment(time).format('mm')}</span></div>
                                <div className="Date">{moment(time).format('dddd D MMMM YYYY')}</div>
                            </div>)
                        }
                        return (<div className={`Clock ${intClocks.length == 2 ? 'Clock2' : intClocks.length == 3 ? 'Clock3' : 'Clock1'}`}>
                            <div className="City">{clock.city}</div>
                            <div className="Time"><span className='Hour'>{moment(time).tz(clock.timezone).format('H')}</span>:<span className='Minute'>{moment(time).tz(clock.timezone).format('mm')}</span></div>
                            <div className="Date">{moment(time).tz(clock.timezone).format('ddd D')}</div>
                        </div>)
                    })
                    }
                </div>
                {/* <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src={require("./glassworkspace.png")} alt='Cloud Logo' style={{ height: 30 }}></img>
                </div> */}
            </div>
        </div>
        </div>)
    }

    if (locked && showKeypad) {
        return (
            <div className={`AppRoot ${blackBg ? 'AppRootNoBg' : ''}`}><div className='AppRootInnerOverlay'>
                <div className='SplashScreen' onClick={() => {
                    setLastScreenActive(moment().toISOString())
                    setBlackBg(false)
                }}>
                    <div className='splash-back'>
                        <FaArrowLeft style={{fontSize: 18}} onClick={() => setShowKeypad(false)} />
                    </div>
                    <Keypad onPassCorrect={() => {setShowKeypad(false); setLocked(false);}} />
                </div>
            </div>
            </div>
        )
    }

    const navigateToHome = () => {
        if (ordoscopeVersion && ordoscopeVersion !== '' && ordoscopeVersion !== null && ordoscopeVersion !== undefined) {
            window.location.href = `/home?scope=${ordoscopeVersion}`;
        } else {
            window.location.href = '/home';
        }
    }

    return (<div className={`AppRoot ${blackBg ? 'AppRootNoBg' : ''}`}><div className='AppRootInnerOverlay'>
        {fireWorksOn && <div className='Fireworks'>
            <FireworksDisplay />
        </div>}
        <div className='SplashMainContent'>
        {showProfilePic && <div className="ProfilePreview">
            <img src={profilePic} alt="Profile Picture" style={{ width: '320px', height: '320px', borderRadius: '12px' }} onClick={() => setShowProfilePic(!showProfilePic)} />
            {/* <div className="ProfileCaption">Howdy, {username}</div> */}
        </div>}
        <div className='SplashScreen' onClick={() => {
            setLastScreenActive(moment().toISOString())
            setBlackBg(false)
        }} style={{paddingTop: 0}}>
            <div className='UserInfo'>
                {profilePic!= null && <div className='ProfilePic' style={{ borderRadius: 24 }} onClick={() => setShowProfilePic(!showProfilePic)}>
                    <img src={profilePic} alt="Profile Picture" style={{ width: '24px', height: '24px', borderRadius: '12px' }} />
                    <div className='balance'>{balance}</div>
                </div>}
                {/* <div className='Username' style={{ marginLeft: 12 }} onClick={() => window.location.href = '/login'}>
                    {username}
                </div> */}
            </div>


            <div className="SplashClock" onClick={navigateToHome}>
                {intClocks.map((clock, index) => {
                    if (clock == '') {
                        return (<div className={`Clock Clock1`}>
                            <div className="Time"><span className='Hour'>{moment(time).format('H')}</span>:<span className='Minute'>{moment(time).format('mm')}</span></div>
                            <div className="Date">{moment(time).format('dddd D MMMM YYYY')}</div>
                        </div>)
                    }
                    return (<div className={`Clock ${intClocks.length == 2 ? 'Clock2' : intClocks.length == 3 ? 'Clock3' : 'Clock1'}`}>
                        <div className="City">{clock.city}</div>
                        <div className="Time"><span className='Hour'>{moment(time).tz(clock.timezone).format('H')}</span>:<span className='Minute'>{moment(time).tz(clock.timezone).format('mm')}</span></div>
                        <div className="Date">{moment(time).tz(clock.timezone).format('ddd D')}</div>
                    </div>)
                })
                }
            </div>
            <div style={{ marginTop: 10, textAlign: 'center' }}>
                <img src={require("./ordocloud.png")} alt='Cloud Logo' style={{ width: 410 }}></img>
            </div>
            {celebrationMessage != '' && <div className='CelebrationMessage'>
                <div>🎉 {celebrationMessage} 🎉</div>
            </div>}
            {celebrationMessage == '' && <div className='SplashNavs'>
                <div className='NavItem' onClick={navigateToHome}>Timer</div>
                <div className='NavItem' onClick={() => window.location.href='/edit'}> Daily </div>
                <div className='NavItem' onClick={() => window.location.href='/editany'}> Any </div>
                <div className='NavItem' onClick={() => window.location.href='/todoedit'}> Todos </div>
                <div className='NavItem' onClick={() => window.location.href='/review'}> Review</div>
                <div className='NavItem' onClick={() => setLocked(true)}> <FaLockOpen /> </div>
            </div>}

            {/* <div className='SplashNavs'>
                <span style={{fontSize: 18}}>🎉 🎉 🎉 Happy Birthday 🎉 🎉 🎉</span>
            </div> */}


        </div></div></div></div>)
}

export default Splash;