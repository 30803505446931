export const fetchBalance = async () => {
    const userInfo = await fetch('https://paymemobile.fr/userinfo?token=' + localStorage.getItem('token')) 
    const userInfoJson = await userInfo.json()
    let balance = userInfoJson.balance || 0
    return Number(balance).toFixed(2)
}

export const syncBalance = async () => {
    const response = await fetch('https://paymemobile.fr/syncbalance?token=' + localStorage.getItem('token'))
    return await fetchBalance()
}